@use "../../utils/mixin.scss" as mix;

.header {
  margin: 0 auto;
  margin-bottom: 25px;
  padding: 0 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: mix.$header__bg;
  height: 60px;
  .header-base {
    max-width: 1030px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    a img {
      margin-top: 4px;
    }
    .input-wrapper {
      min-width: 170px;
      max-width: 272px;
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      border: 1px #3a3f46 solid;
      display: flex;
      justify-content: center;
      align-items: center;
      background: mix.$navbar__bg;
      border-radius: 5px;
      span {
        padding: 0 7px;
        padding-top: 1px;
        margin-right: 5px;
        border-radius: 5px;
        color: mix.$base__color;
        border: 1px #3a3f46 solid;
      }
      input {
        border-radius: 5px;
        padding: 5px 0 5px 13px;
        background: inherit;
        color: mix.$base__color;
        border: 0;
        outline: none;
        width: 100%;
        font-weight: 300;
      }
    }
    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      margin-right: 10px;
    }
    li {
      a {
        padding: 16px 0;
        color: mix.$base__color;
        font-size: 14.2px;
        font-weight: 300;
        &:hover {
          color: mix.$base__color-hover;
        }
      }
      &:last-child {
        margin-right: 16px;
      }
    }
  }
  .header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: mix.$base__color-hover;
      }
    }
    .avatar {
      display: inline-block;
      @include mix.border;
      border-radius: 50% !important;
    }
  }
  .input {
    width: 100% !important;
  }
  .menu {
    display: none;
  }
  .text {
    display: none;
  }
}
.navbar {
  position: sticky;
  z-index: 1;
  top: 0px;
  height: 48px;
  background-color: #0d1117;
  border-bottom: solid 2px mix.$border;

  .navbar__wrapper {
    display: flex;
    align-items: center;
    // padding-top: 8px;
  }

  .navbar__name {
    min-width: 350px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: mix.$base__color;
    margin-left: 8px;
    img {
      border-radius: 50%;
    }
  }
  ul {
    // margin-top: 8px;
    padding-right: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 48px;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 2px;
    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 300;
      color: mix.$base__color;
      font-size: 14px;
      padding: 10px 8px;
      border-bottom: 2px solid transparent;
      &:hover::after {
        content: "";
        display: block !important;
        position: absolute;
        width: 100%;
        height: 25%;
        padding: 10px 0;
        background: #2a2e35;
        border-radius: 5px;
        cursor: pointer;
        z-index: -1;
      }
      p {
        padding-left: 5px;
      }
      span {
        font-size: 11px;
      }
    }
    &.repository {
      p {
        padding-right: 5px;
      }
      span {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 20px;
        border-radius: 40px;
        background-color: #4e4e4eaf;
      }
    }
    &:nth-child(5) {
      p {
        padding-right: 5px;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 20px;
        border-radius: 40px;
        background-color: #4e4e4eaf;
      }
    }
    &.dot {
      display: none;
    }
    .active {
      border-bottom: 2px solid #e87a61;
    }
  }
  .hidden {
    overflow: hidden;
  }
  .innerLink__wrapper {
    display: none;
  }
}
@include mix.tablet {
  .header {
    position: relative;
    margin-bottom: 10px;
    .header-base {
      flex-direction: row-reverse;
      .input-wrapper {
        margin-bottom: 15px;
        max-width: 733px;
        width: 100% !important;
      }
      ul {
        display: none;
        position: absolute;
        top: 50px;
        width: 100%;
        max-width: 748px;
        left: 0px;
        padding: 10px 16px;
        margin: 0 !important;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #161b22;
        gap: 0;
        li {
          a {
            border-top: 1px solid rgba(255, 255, 255, 0.164);
            width: 100%;
            max-width: 715px;
            margin: 0 !important;
            display: inline-block;
            padding: 10px 0;
          }
        }
      }
    }
    .header-icon {
      div {
        display: none;
      }
    }
    .text {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 20px;
        height: 20px;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
      }
    }
    .menu {
      display: block;
      position: absolute;
      left: 0px;
      margin-left: 16px;
      cursor: pointer;
    }
    .menuOn {
      z-index: 999;
      display: block !important;
    }
    .signOut-logo {
      position: absolute;
      left: 13px;
    }
  }
  .navbar {
    display: none;
  }
}
.hide {
  position: absolute;
  display: none;
}

@media screen and (max-width: 1030px) {
  .navbar {
    // position: relative;
    ul {
      li {
        &:nth-child(5) {
          display: none;
        }
        &:nth-child(6) {
          display: block;
          position: absolute;
          top: -5px;
          right: 40px;
        }
      }
    }
    .innerLink__wrapper {
      display: block;
      position: absolute;
      right: 30px;
      top: 20px;
      padding: 8px 8px 8px 16px;
      background: #161b22;
      border-radius: 10px;
      width: 250px;
      border: solid 1px #464545b0;
      &:after {
        top: -7.5px;
        right: 15px;
        position: absolute;
        content: " ";
        display: inline-block;
        width: 12px;
        height: 12px;
        background-color: #161b22;
        transform: rotate(45deg);
        border: 1px solid;
        border-color: #464545b0 transparent #161b22 #464545b0;
      }
      .link {
        &:nth-child(2),
        &:nth-child(3) {
          display: none;
        }
        a {
          display: inline-block;
          padding: 5px;
          color: mix.$base__color;
        }
      }
    }
  }
}
@media screen and (max-width: 920px) {
  .navbar ul li:nth-child(4) {
    display: none;
  }
  .innerLink__wrapper {
    .link {
      &:nth-child(2) {
        display: block !important;
      }
    }
  }
}
@media screen and (max-width: 805px) {
  .navbar ul li:nth-child(3) {
    display: none;
  }
  .innerLink__wrapper {
    .link {
      &:nth-child(3) {
        display: block !important;
      }
    }
  }
}

@include mix.mobile {
  .header {
    position: fixed;
    top: 0;
    z-index: 99;
  }
}
