@use "../../utils/mixin.scss" as mix;

.repos {
  width: 100%;
}
.repos__form {
  padding: 16px 0;
  display: flex;
  border-bottom: mix.$border 1px solid;
  .input__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .input {
      max-width: 520px;
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
  .form__search {
    padding: 5px 16px;
    outline: none;
    @include mix.border;
    background-color: transparent;
    @include mix.color;
    &:focus {
      outline: 2px solid #58a6ff;
      outline-style: outset;
    }
  }
  .option__wrapper {
    display: flex;
    position: relative;
    gap: 5px;
    .form__drop {
      position: relative;
      select {
        width: 65px;
      }
      img {
        position: absolute;
        top: 40%;
        right: 10px;
        bottom: 45%;
        margin: auto;
      }
      &:nth-child(2) {
        select {
          min-width: 100px;
        }
      }
    }
    .new__wrapper {
      display: flex;
      position: relative;
      margin-left: 20px;
      cursor: pointer;
      a {
        width: 75px;
        padding-left: 30px;
      }
      svg {
        fill: white;
        position: absolute;
        left: 10px;
        top: 50%;
        bottom: 50%;
        margin: auto;
      }
    }
  }
  .form__select {
    margin-right: 5px;
    width: 100%;
    min-width: 50px;
    padding: 5px 16px;
    background-color: mix.$btn__bg;
    outline: none;
    cursor: pointer;
    @include mix.color;
    @include mix.border;
    appearance: none;
    &:nth-child(3) {
      padding-right: 10px;
    }
    &:hover {
      border-color: #999;
      background-color: #444444da;
    }
    option {
      @include mix.border;
    }
  }
  .repos__form-link {
    @include mix.btn__green;
  }
}
.repos__wrapper {
  li {
    padding: 25px 0;
    border-bottom: 1px solid mix.$border;

    .name {
      padding-bottom: 10px;
      color: #58a6ff;
      font-size: 20px;
      .name__wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-size: 14px;
          @include mix.border;
          border-radius: 15px;
          @include mix.color;
          opacity: 0.7;
          padding: 3px 8px;
          text-transform: capitalize;
        }
      }
    }
    .star__wrapper {
      padding: 0px 10px;
      @include mix.border;
      @include mix.color;
      cursor: pointer;
      background-color: #21262d;
      p {
        margin: 0 10px;
        font-size: 12px;
      }
      .drop {
        padding: 8px 0;
        padding-left: 10px;
        border-left: 1px solid;
        border-left-color: mix.$border;
      }
    }
    .lang__wrapper {
      display: flex;
      font-size: 12px;
      color: #999;
      font-weight: 400;
      position: relative;
      p {
        margin-right: 10px;
        margin-left: 15px;
        &::before {
          content: " ";
          position: absolute;
          left: 0;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: coral;
        }
      }
    }
  }
}

@include mix.mobile {
  .repos__form {
    .input__wrapper {
      justify-content: center;
      flex-wrap: wrap;
    }
    .option__wrapper{
      .new__wrapper{
        margin: 0;
      }
    }
  }
  .repos__wrapper .name {
    .name__wrapper {
      h4 {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 150px;
      }
    }
  }
}
