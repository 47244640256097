@use "../../utils/mixin.scss" as mix;
.single {
  margin-top: 5px;
  padding: 24px;
  padding-left: 0;
  width: 100%;
  .title__wrapper {
    h3 {
      font-weight: 400;
      font-size: 15px;
      color: mix.$base__color;
      opacity: 0.8;
    }
    p {
      font-size: 13px;
      font-weight: 300;
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    //  width: 100%;
    flex-wrap: wrap;
    gap: 10px;
    //  max-width: 910px;
    li {
      margin-top: 8px;
      padding: 16px;
      width: 49%;
      // min-width: 266px;
      // max-width: 435px;
      @include mix.border;
      .repo {
        .flex {
          flex-wrap: wrap;
          a {
            margin: 0 10px;
            color: mix.$blue;
            font-size: 14.5px;
          }
          span {
            display: inline-block;
            padding: 5px;
            text-transform: capitalize;
            border-radius: 50px !important;
            @include mix.border;
            font-size: 12px;
            line-height: 11px;
            color: mix.$base__color-hover;
          }
        }
        svg {
          fill: mix.$base__color-hover;
          cursor: grabbing;
        }
      }
    }
    .description {
      margin-top: 8px;
      color: mix.$base__color-hover;
      font-size: 12px;
    }
    .column2 {
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 15px;
      .sircle {
        display: inline-block;
        width: 10px;
        height: 12px;
        background-color: rgb(245, 245, 53);
        border-radius: 50%;
      }
      p {
        margin-left: 5px;
        font-size: 13px;
      }
      svg {
        fill: mix.$base__color-hover;
      }
    }
  }
}

@media only screen and (max-width: 888px) {
  .single {
    ul {
      // display: flex;
      li {
        width: 100%;
      }
    }
  }
}

@include mix.tablet {
  .single {
    padding-right: 0;
    // ul {
    // }
  }
}
