$base__bg: #161b22;
$base__color: #ffffffe0;
$blue: #5b9cff;
$base__color-hover: #a7a8a8;

$header__bg: #161b22;
$navbar__bg: #0d1117;
$border: #464545b0;
$link__bottom-bg: #f78166;
$btn__bg: #21262d;
$btn__green: #238636;

@mixin btn__green{
  padding: 5px 16px;
  background-color: $btn__green;
  color: $base__color;
  font-weight: 400;
  border-radius: 5px;
}
@mixin color {
  color: $base__color;
  font-weight: 400;
  // opacity: .7;
}

@mixin border {
  border-radius: 5px;
  border: 1px solid $border;
}

// @mixin desktop {
//   @media only screen and (max-width: 1013px) {
//     @content;
//   }
// }

@mixin tablet {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 512px) {
    @content;
  }
}
