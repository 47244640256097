@use "../../utils/mixin.scss" as mix;

/* roboto-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v30-latin-300.woff2") format("woff2"),
    /* Chrome 36+, Opera 23+, Firefox 39+ */
      url("../fonts/roboto-v30-latin-300.woff") format("woff"); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/roboto-v30-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/roboto-v30-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("../fonts/roboto-v30-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/roboto-v30-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/roboto-v30-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../fonts/roboto-v30-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // transition: 0.4s all ease-in;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.container {
  max-width: 1295px;
  padding: 0 25px;
  margin: 0 auto;
}

body {
  font-family: "Roboto";
  background-color: mix.$navbar__bg;
}

@media only screen and (max-width: 1000px) {
  body {
    font-size: 85%;
  }
}

@include mix.tablet {
  .container {
    padding: 0 16px;
  }
}

@include mix.mobile {
  body {
    background-color: mix.$base__bg;
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: mix.$base__color-hover;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  // background: #f1f1f1; 
  background: #444; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}
