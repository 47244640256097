@use "../../utils/mixin.scss" as mix;

.footer {
    padding: 0 32px;
    // .logo__wrapper{
    // padding: 50px 0;
    .footer__wrapper {
        border-top: 1px solid mix.$border;
        padding: 50px 0;
        gap: 70px;
        justify-content: flex-start;
    }
    p{
        padding-left: 5px;
        // padding-top: 3px;
        // align-self: flex-end;
        font-size: 13px;
    }
    // }
    .link__wrapper {
        font-size: 13px;
        gap: 40px;
        a {
            // margin-left: 40px;
            color: #58a6ff;
        }
    }
}

@media only screen and (max-width: 1130px) {
    .footer{
        .flex{
            justify-content: center;
            flex-wrap: wrap-reverse;
        }
    }
}
