@use "../../utils/mixin.scss" as mix;

.project__wrapper {
    margin-top: 16px;
    width: 100%;
    .form__wrapper {
        margin-top: 16px;
        // width: 100%;
        .input__wrapper {
            position: relative;
            svg {
                position: absolute;
                &:first-child{
                    left: 8px;
                }
                &:last-child{
                    right: 8px;
                    cursor: pointer;
                }
            }
            width: 85%;
            input {
                color: mix.$base__color;
                width: 100%;
                border-radius: 8px;
                padding: 8px 15px 8px 30px;
                outline: none;
                border: 1px solid mix.$border;
                background-color: transparent;
                // width: 95%;
                // appearance: none;
                &:focus {
                    outline: 2px solid #58a6ff;
                    outline-style: outset;
                }
            }
        }
        button {
            margin-left: 20px;
            padding: 10px 15px;
            border: none;
            border-radius: 8px;
            background-color: #1f6feb;
            color: white;
            font-weight: 700;
            text-wrap: nowrap;
        }
    }
}

@media only screen and (max-width: 1130px) {
}