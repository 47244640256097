@use "../../utils/mixin.scss" as mix;

.steam {
  .page-wrapper {
    display: flex;
    padding-bottom: 50px;
  }

  .navbar-mobile {
    display: none;
  }
}

@include mix.tablet {
  .steam {
    .page-wrapper {
      flex-direction: column;
      .navbar {
        margin: 1px;
        //   border: 1px solid transparent;
        position: sticky;
        top: 10px;
        background-color: transparent;
        display: block !important;
        overflow-y: auto;
        height: auto;
        ul {
          top: 10px;
          margin: 0 auto;
          padding: 0;
          li {
            display: block !important;
          }
        }
      }
    }
  }
}
