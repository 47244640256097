@use "../../utils/mixin.scss" as mix;

.sidebar {
  max-width: 300px;
  position: relative;
  margin-right: 35px;
  &__img {
    position: relative;
    top: -30px;
    left: 5px;
    margin-bottom: -35px;
    background-color: #999;
    border: solid #1a1f2a 1px;
    border-radius: 50%;
    display: inline-block; // Rasmdan keyin keraksiz
    z-index: 99;
  }
  .name {
    padding: 16px 0;
    color: mix.$base__color;
    font-size: 20px;
    p {
      font-weight: 300;
      opacity: 0.6;
    }
  }
  .title {
    color: mix.$base__color;
    font-weight: 300;
    opacity: 0.9;
    p {
      padding-bottom: 16px;
    }
    button {
      width: 100%;
      height: 32.5px;
      color: mix.$base__color;
      font-weight: 300;
      background-color: mix.$btn__bg;
      @include mix.border;
      margin-bottom: 16px;
    }
  }
  .user-wrapper {
    display: flex;
    .followers {
      display: flex;
      font-weight: 300;
      svg {
        opacity: 0.7;
        margin-top: 2px;
        fill: mix.$base__color;
      }
      span {
        margin: 0 5px;
      }
      p {
        opacity: 0.7;
      }
    }
    .following {
      span {
        margin: 0 0 0 5px;
      }
      span:last-child {
        opacity: 0.7;
      }
    }
  }
  .company {
    display: flex;
    align-items: center;
    color: mix.$base__color;
    font-weight: 300;
    svg {
      opacity: 0.7;
      fill: mix.$base__color;
      margin-right: 7px;
    }
  }
  .location {
    display: flex;
    align-items: center;
    font-weight: 300;
    color: mix.$base__color;
    svg {
      opacity: 0.7;
      fill: mix.$base__color;
      margin-right: 7px;
    }
  }
  .time {
    display: flex;
    align-items: center;
    height: 20px;
    // padding-bottom: 5px;
    @include mix.color;
    // &::after {
    //   position: absolute;
    //   bottom: -16px;
    //   content: "";
    //   display: inline-block;
    //   width: 100%;
    //   height: 2px;
    //   background: #1a1f2a;
    // }
    svg {
      opacity: 0.7;
      fill: mix.$base__color;
      margin-right: 7px;
    }
    time {
      font-size: 14px;
    }
  }
  .blog{
    display: flex;
    align-items: center;
    height: 15px;
    @include mix.color;
    svg{
      opacity: 0.7;
      fill: mix.$base__color;
      margin-right: 7px;
    }
    a{
      color: mix.$base__color;
    }
  }
  ul {
    .organization {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
  }
}

@media only screen and (max-width: 933px) {
  .sidebar {
    &__img {
      width: 256px !important;
      height: 256px !important;
    }
  }
}

@include mix.tablet {
  .sidebar {
    width: 100% !important;
    max-width: none;
    margin: 0;
    padding: 0;
    ul {
      display: flex;
      flex-direction: column;
      .avatar__wrapper {
        display: flex;
        align-items: center;
        .name {
          width: 100% !important;
          h3,
          p {
            width: 100% !important;
          }
        }

        img {
          top: -15px;
          width: 100% !important;
          height: auto !important;
          max-width: 120px !important;
          max-height: 120px !important;
        }
        &:first-child {
          display: flex;
          gap: 16px;
          margin-bottom: 35px;
        }
      }
      .organization {
        display: none !important;
      }
      .title {
        span,
        p {
          font-size: 15px !important;
        }
      }
    }
  }
}

@include mix.mobile{
  .sidebar{
    margin-top: 70px;
    &__img{
      z-index: -1;
    }
  }
}